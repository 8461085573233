@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/_mixins.scss";
@import "~bootstrap/scss/_reboot.scss";
@import "~bootstrap/scss/_utilities.scss";
@import "~bootstrap/scss/_grid.scss";
@import "~bootstrap/scss/_buttons";
@import "~bootstrap/scss/_badge";


$primaryColor: #138b80;

body {
  font-size: .9em;
  background-color: #151515;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  // font-family: 'Poppins', sans-serif;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}
a {
  color: $primaryColor ;
  outline: none;
  text-decoration: none !important;
}
a:hover {
  color: lighten($color: $primaryColor, $amount: 5);
}
p {
  font-size: 14px;
}
h3 {
  font-size: 16px;
  margin: 0;
}
strong {
  font-weight: 600;
}
.hr {
  height: 1px;
  margin-bottom: 12px;
  border: none;
  border-bottom: 1px solid #ececec;
  margin-top: 0;
}

.title {
  font-weight: 600;
}

.container {
  max-width: 920px;
  margin: auto;
}