.header {
  // background: #26282c;
  // background: #181A1F;
  // background:  #39414A; 
  // background-color: #5da0a1;
  color: #fff;
  position: relative;
  // height: 30em;

}

.intro__h2 {
  font-weight: 400;
  font-size: 1.2em;
  color: #cacaca;
}

.intro__h1 {
  font-size: 3em;
  font-weight: 800;
}

.header__main {
  padding: 8em 10px;
  width: 80%;
  max-width: 600px;
  z-index: 100;
  position: relative;
  text-align: left;
  line-height: 1.3;
}

.header_logo  {
  display: block;
  border-radius: 10px;
  overflow: hidden;
  width: 5em;
  height: 5em;
  margin-bottom: 5em;
  background-image: url("/static/images/logo.png");
  background-size: 102%;
}
.header_logo img {
  width: 100%;
  height: 100%;
}