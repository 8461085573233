.bgAppIcons {
  position: absolute;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  bottom: 0;
  // background: green;
  z-index: 1;
}

.bgAppIcons__item {
  // filter:;
  // filter: blur(10px) ;
  // opacity: .9;
  // width: 8em;
  transition: opacity 1s;
}