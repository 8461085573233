.Footer {
  // background-color: #2a2d2f;
  background-color: #0e0e0e;
  padding: 5em 0;
  margin-top: 5em;
  text-align: center;
  border-top: 1px solid #000;
  &__socialItem {
    margin: 0 10px;
  }

  &__socialItem__Img {
    width: 2.3em;
    height: 2.3em;
  }
}

.CopyRight {
  margin-top: 2em;
  color: #aaa;
}
.CodeWithLove  {
  background: #0e0e0e;
  border-top: 1px solid #000;
  color: #ccc;
  text-align: center;
  padding: 5px;
  code {
    padding: 1px 5px;
    font-weight: bold;
  }
  .codeChar {
    color: #ffd300;
  }
}
